import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { LoginPage } from "./Page/LoginPage";
import { SuperAdminPanel } from "./Page/SuperAdminPanel";
import { ChangePasswordPage } from "./Page/ChangePasswordPage";
import { AdvertisementPage } from "./Page/AdvertisementPage";
import { Navbar } from "./Components/Navbar";

function App() {
  const superAdminToken = localStorage.getItem("superAdminToken");

  return (
    <div>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route 
            path="/login" 
            element={superAdminToken ? <Navigate to="/" /> : <LoginPage />} 
          />
          <Route 
            path="/" 
            element={superAdminToken ? <SuperAdminPanel /> : <Navigate to="/login" />} 
          />
          <Route 
            path="/change-password/:status" 
            element={superAdminToken ? <ChangePasswordPage /> : <Navigate to="/login" />} 
          />
          <Route 
            path="/advertisement" 
            element={superAdminToken ? <AdvertisementPage /> : <Navigate to="/login" />} 
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
