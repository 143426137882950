import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export const LoginPage = () => {
    const [credentials, setCredentials] = useState({ username: "", password: "" });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({});
    const navigate = useNavigate();

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        setCredentials((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        setError({});

        if (!credentials.username) {
            setError((prev) => ({
                ...prev,
                username: "Username is required",
            }));
        }

        if (!credentials.password) {
            setError((prev) => ({
                ...prev,
                password: "Password is required",
            }));
        }

        if (!credentials.username || !credentials.password) {
            return;
        }

        try {
            setLoading(true);
            const res = await axios.post("https://property-stock-backend.vercel.app/super-admin/sign-in", credentials);

            if (res.status === 200 || res.status === 201) {
                localStorage.setItem("superAdminToken", res.data.response);
                navigate("/");
                window.location.reload();
            } else {
                throw new Error("Login failed");
            }
        }
        catch (error) {
            if (error.response) {
                console.error("Response error:", error.response.data);
                alert(error.response.data.message || "An error occurred during login");
            }
            else {
                console.error("Error:", error);
                alert("An unknown error occurred");
            }
        }
        finally {
            setLoading(false);
        }
    };


    return (
        <div className="flex flex-col justify-center items-center w-full">
            <form
                onSubmit={handleFormSubmit}
                className="md:w-1/4 md:h-[70vh] h-[70vh] w-[90%] shadow-xl md:bg-slate-200 bg-slate-200 rounded-lg md:mt-20 mt-10 flex justify-center items-center"
            >
                <div className="flex flex-col items-center md:w-4/5 w-[90%] h-[90%] gap-8">
                    <h1 className="mt-2 mb-8 font-bold md:text-4xl text-2xl">
                        Sign In
                    </h1>

                    <div className="w-full">
                        <label
                            htmlFor="username"
                            className={`block mb-2 text-sm font-medium ${error.username ? 'text-red-500 font-bold' : 'text-gray-700'}`}
                        >
                            Username
                        </label>
                        <input
                            type="text"
                            id="username"
                            placeholder="Enter Username"
                            name="username"
                            className={`border-2 rounded-md w-full h-11 pl-3 outline-0 ${error.username ? 'border-red-500' : 'border-gray-400'}`}
                            onChange={handleInputChange}
                            value={credentials.username}
                        />
                    </div>

                    <div className="w-full">
                        <label
                            htmlFor="password"
                            className={`block mb-2 text-sm font-medium ${error.password ? 'text-red-500 font-bold' : 'text-gray-700'}`}
                        >
                            Password
                        </label>
                        <input
                            type="password"
                            id="password"
                            placeholder="Enter Password"
                            name="password"
                            className={`border-2 rounded-md w-full h-11 pl-3 outline-0 ${error.password ? 'border-red-500' : 'border-gray-400'}`}
                            onChange={handleInputChange}
                            value={credentials.password}
                        />
                    </div>

                    <button
                        type="submit"
                        disabled={loading}
                        className="md:w-1/2 w-full text-white font-bold text-lg bg-[#25395A] h-14 rounded-md mt-10 hover:bg-[#1b417e] flex items-center justify-center"
                    >
                        {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : "Sign In"}
                    </button>
                </div>
            </form>
        </div>
    );
};
