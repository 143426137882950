import { useState } from "react";
import menu from "../Assets/menu.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export const Navbar = () => {
    const [menuToggle, setMenuToggle] = useState(false);
    const navigate = useNavigate();
    const superAdminToken = localStorage.getItem("superAdminToken");

    const handleLogout = () => {
        const superAdminToken = localStorage.getItem("superAdminToken");

        if (!superAdminToken) {
            console.error("No token found, user is not logged in.");
            return;
        }

        axios.post(
            "https://property-stock-backend.vercel.app/super-admin/sign-out",
            {},
            {
                headers: {
                    Authorization: `Bearer ${superAdminToken}`,
                },
            }
        )
            .then((res) => {
                console.log(res);
                localStorage.removeItem("superAdminToken");
                navigate("/login");
                window.location.reload();
            })
            .catch((error) => {
                if (error.response) {
                    console.error("Logout failed:", error.response.data);
                }
                else {
                    console.error("Logout failed:", error.message);
                }
            });
    };

    return (
        <div className="shadow-lg w-full h-16 flex justify-center items-center">
            <h1
                className="font-bold text-[#E73437] md:text-4xl text-xl cursor-pointer"
                onClick={() => navigate("/")}
            > Super Admin Panel
            </h1>
            {superAdminToken && <>
                <img
                    className="md:w-12 absolute right-4 w-8 cursor-pointer"
                    src={menu}
                    onClick={() => setMenuToggle(!menuToggle)}
                    alt="menu"
                />
                {menuToggle &&
                    <button
                        className="md:w-56 w-40 md:h-16 h-12 rounded-lg text-white md:text-2xl text-lg font-bold tracking-wider absolute right-4 top-20 bg-[#E73437] z-10 cursor-pointer"
                        onClick={handleLogout}
                    > Logout
                    </button>
                }
            </>}
        </div>
    )
}