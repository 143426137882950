import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Switch from 'react-switch';
import axios from 'axios';

export const SuperAdminPanel = () => {
    const [toggleAdminLogin, setToggleAdminLogin] = useState(false);
    const [toggleForm, setToggleForm] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchInitialSettings = async () => {
            const superAdminToken = localStorage.getItem("superAdminToken");

            if (!superAdminToken) {
                alert("You have been Logout!");
                localStorage.removeItem(superAdminToken);
                return;
            }

            try {
                const response = await axios.get("https://property-stock-backend.vercel.app/super-admin/advertisement", {
                    headers: {
                        Authorization: `Bearer ${superAdminToken}`,
                    },
                });

                setToggleAdminLogin(response.data.response.adminLogin);
                setToggleForm(response.data.response.addProject);
            } 
            catch (error) {
                console.error("Error fetching initial settings:", error);
                alert(error.response?.data?.message || "Failed to fetch initial settings");
            }
        };

        fetchInitialSettings();
    }, []);

    const handleAdminLoginToggle = async () => {
        const updatedAdminLogin = !toggleAdminLogin;
        setToggleAdminLogin(updatedAdminLogin);

        const superAdminToken = localStorage.getItem("superAdminToken");

        if (!superAdminToken) {
            alert("You are not Logged In");
            localStorage.removeItem(superAdminToken);
            return;
        }

        try {
            await axios.post(
                "https://property-stock-backend.vercel.app/super-admin/update",
                { adminLogin: updatedAdminLogin },
                {
                    headers: {
                        Authorization: `Bearer ${superAdminToken}`,
                    },
                }
            );
            window.location.reload();
            alert("Admin login updated successfully");
        }
        catch (error) {
            console.error("Error updating admin login:", error);
            alert(error.response.data.message);
        }
    };

    const handleFormToggle = async () => {
        const updatedAddProject = !toggleForm;
        setToggleForm(updatedAddProject);

        const superAdminToken = localStorage.getItem("superAdminToken");

        if (!superAdminToken) {
            alert("You are not Logged In");
            localStorage.removeItem(superAdminToken);
            return;
        }

        try {
            await axios.post(
                "https://property-stock-backend.vercel.app/super-admin/update",
                { addProject: updatedAddProject },
                {
                    headers: {
                        Authorization: `Bearer ${superAdminToken}`,
                    },
                }
            );
            alert("Add properties toggle updated successfully");
            window.location.reload();
        }
        catch (error) {
            console.error("Error updating add project toggle:", error);
            alert(error.response.data.message);
        }
    };

    return (
        <div className="flex flex-col items-center mt-7 mb-10">
            <div className="flex flex-col items-center h-24 justify-between">
                <div className="flex flex-row text-xl font-bold w-52 h-16 items-center justify-between">
                    <p>Off</p>
                    <Switch
                        checked={toggleAdminLogin}
                        onChange={handleAdminLoginToggle}
                        onColor="#86d3ff"
                        onHandleColor="#2693e6"
                        handleDiameter={30}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={20}
                        width={48}
                        className="react-switch"
                    />
                    <p>On</p>
                </div>
                <p className='text-xl font-bold'>Admin Login</p>
            </div>

            <div className="flex flex-col items-center h-24 mt-16 justify-between">
                <div className="flex flex-row text-xl font-bold w-52 h-16 items-center justify-between">
                    <p>Off</p>
                    <Switch
                        checked={toggleForm}
                        onChange={handleFormToggle}
                        onColor="#86d3ff"
                        onHandleColor="#2693e6"
                        handleDiameter={30}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={20}
                        width={48}
                        className="react-switch"
                    />
                    <p>On</p>
                </div>
                <p className='text-xl font-bold'>Add Properties Free</p>
            </div>

            <button
                className='bg-[#E73437] text-white font-bold md:text-2xl text-xl w-64 md:h-24 h-16 rounded-lg md:mt-6 mt-12'
                onClick={() => navigate("/advertisement")}
            >
                Advertisement
            </button>

            <button
                className='bg-[#E73437] text-white font-bold md:text-2xl text-xl w-64 md:h-24 h-16 rounded-lg mt-6'
                onClick={() => navigate("/change-password/admin")}
            >
                Change Admin Password
            </button>

            <button
                className='bg-[#E73437] text-white font-bold md:text-2xl text-xl w-64 md:h-24 h-16 rounded-lg mt-6'
                onClick={() => navigate("/change-password/super-admin")}
            >
                Change Super Admin Password
            </button>
        </div>
    );
};
