import { useState, useEffect } from "react";
import upload from "../Assets/upload.svg";
import cancel from "../Assets/cancel.svg";
import tick from "../Assets/tick.svg";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export const AdvertisementPage = () => {
    const [advertisement, setAdvertisement] = useState({});
    const [uploadedImages, setUploadedImages] = useState({});
    const [removedImages, setRemovedImages] = useState({}); // Track removed images
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchAdvertisements = async () => {
            try {
                const response = await axios.get("https://property-stock-backend.vercel.app/super-admin/advertisement", {});
                setAdvertisement(response.data.response.advertisements);
            } catch (error) {
                console.error("Error fetching advertisements:", error);
                alert(error.response.data.message);
            }
        };

        fetchAdvertisements();
    }, []);

    const handleUploadClick = (category, buttonIndex) => {
        const inputElement = document.getElementById(`${category}-${buttonIndex}-file-input`);
        if (inputElement) {
            inputElement.click();
        }
    };

    const handleFileChange = (e, category, buttonIndex) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            const key = `${category.toLowerCase()}-${buttonIndex}`;
            setUploadedImages((prevImages) => ({
                ...prevImages,
                [key]: file
            }));

            // Remove the image removed status if re-uploaded
            setRemovedImages((prevRemovedImages) => {
                const updatedRemovedImages = { ...prevRemovedImages };
                delete updatedRemovedImages[key];
                return updatedRemovedImages;
            });
        }
    };

    const handleCancelClick = (category, buttonIndex) => {
        const key = `${category.toLowerCase()}-${buttonIndex}`;
        setUploadedImages((prevImages) => {
            const updatedImages = { ...prevImages };
            delete updatedImages[key];
            return updatedImages;
        });

        setRemovedImages((prevRemovedImages) => ({
            ...prevRemovedImages,
            [key]: true
        }));

        setAdvertisement((prevAdvertisement) => {
            const updatedAdvertisement = { ...prevAdvertisement };
            delete updatedAdvertisement[key];
            return updatedAdvertisement;
        });

    };

    const categories = [
        { name: "Residential", buttons: 2 },
        { name: "Commercial", buttons: 2 },
        { name: "Plot-Land", buttons: 1 },
        { name: "Financial", buttons: 2 },
        { name: "Logistics", buttons: 1 },
        { name: "Our-Services", buttons: 2 }
    ];

    const uploadImagesToCloudinary = async () => {
        const updatedFormData = { ...advertisement };

        try {
            for (const key in uploadedImages) {
                const file = uploadedImages[key];

                if (file && file.type.startsWith('image/')) {
                    const formData = new FormData();
                    formData.append('file', file);
                    formData.append('upload_preset', 'image_upload');
                    formData.append('cloud_name', 'djjcged23');

                    try {
                        const response = await fetch(`https://api.cloudinary.com/v1_1/djjcged23/image/upload`, {
                            method: 'POST',
                            body: formData,
                        });

                        const result = await response.json();
                        updatedFormData[key] = result.secure_url;

                    } catch (error) {
                        console.error(`Error uploading ${key}:`, error);
                    }
                }
            }
            return updatedFormData;
        } catch (error) {
            alert("Error While Uploading Images");
            console.error('Error uploading images:', error);
        }
    };

    const handleFormSubmit = async () => {
        setLoading(true);
        const uploadedImageUrls = await uploadImagesToCloudinary();
        const newAdvertisement = { ...uploadedImageUrls };

        const payload = {
            advertisements: newAdvertisement
        };

        const superAdminToken = localStorage.getItem("superAdminToken");

        try {
            const response = await axios.post("https://property-stock-backend.vercel.app/super-admin/update", payload, {
                headers: {
                    Authorization: `Bearer ${superAdminToken}`,
                    "Content-Type": "application/json",
                },
            });

            if (response.status === 200) {
                setLoading(false);
                alert(response.data.message);
                window.location.reload();
            }
        }
        catch (error) {
            console.error("Error in update request:", error);
            setLoading(false);
            alert("Error while saving advertisement");
        }
    };

    return (
        <div className="flex flex-col items-center md:mt-11 mt-5">
            <h1 className="md:text-4xl text-3xl font-bold">Advertisement</h1>
            <div className="md:mt-12 md:mt-12 mt-7 flex flex-col">
                {categories.map((category) => (
                    <div key={category.name} className="flex flex-row h-12 items-center mb-14">
                        <h3 className="text-xl font-semibold w-48">{category.name}</h3>
                        <div className="flex flex-row space-x-4">
                            {[...Array(category.buttons)].map((_, index) => {
                                const key = `${category.name.toLowerCase()}-${index + 1}`;
                                const fileSelected = uploadedImages[key];
                                const isRemoved = removedImages[key];
                                return (
                                    <div key={index} className="flex flex-col h-16 justify-between">
                                        <button
                                            className="w-16 flex justify-center"
                                            onClick={() => handleUploadClick(category.name, index + 1)}
                                        >
                                            <img src={fileSelected ? tick : upload} alt={fileSelected ? "Uploaded" : "Upload"} />
                                        </button>
                                        <button
                                            className="w-16 flex justify-center"
                                            onClick={() => handleCancelClick(category.name, index + 1)}
                                        >
                                            <img src={cancel} alt="Cancel" />
                                        </button>
                                        {isRemoved && <p className="text-red-500 text-sm">Image Removed</p>}

                                        <input
                                            type="file"
                                            id={`${category.name}-${index + 1}-file-input`}
                                            onChange={(e) => handleFileChange(e, category.name, index + 1)}
                                            style={{ display: "none" }}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                ))}

                <button
                    disabled={loading}
                    className="mt-10 bg-[#E73437] text-white text-xl font-bold h-16 rounded-lg mb-16"
                    onClick={handleFormSubmit}
                >
                    {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : "Save"}
                </button>
            </div>
        </div>
    );
};
