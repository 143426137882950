import { useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export const ChangePasswordPage = () => {
    const [loading, setLoading] = useState(false);
    const [credentials, setCredentials] = useState({
        username: "",
        oldPassword: "",
        newPassword: ""
    });

    const [error, setError] = useState({
        username: false,
        oldPassword: false,
        newPassword: false
    });

    const { status } = useParams();

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setCredentials((prev) => ({
            ...prev,
            [name]: value,
        }));
    }

    const handleFormSubmit = (event) => {
        event.preventDefault();
        setLoading(true);

        const superAdminToken = localStorage.getItem("superAdminToken");

        if (!superAdminToken) {
            alert("Please Login");
            setLoading(false);
            return;
        }

        setError({ username: false, oldPassword: false, newPassword: false });

        const newErrorState = {
            username: !credentials.username,
            oldPassword: !credentials.oldPassword,
            newPassword: !credentials.newPassword,
        };

        if (Object.values(newErrorState).some(value => value)) {
            setError(newErrorState);
            setLoading(false);
            return;
        }

        if (status === "admin") {
            axios.put(
                "https://property-stock-backend.vercel.app/admin/change-password",
                credentials,
                {
                    headers: {
                        Authorization: `Bearer ${superAdminToken}`,
                    },
                }
            )
                .then((response) => {
                    if (response.status === 200) {
                        localStorage.setItem("token", response.data.response);
                        alert("Password updated successfully");
                        setLoading(false);
                        window.location.reload();
                    }
                })
                .catch((error) => {
                    console.error("Error updating password:", error);
                    setLoading(false);
                    alert(error.response.data.message);
                });
        }

        if (status === "super-admin") {
            axios.put(
                "https://property-stock-backend.vercel.app/super-admin/change-password",
                credentials,
                {
                    headers: {
                        Authorization: `Bearer ${superAdminToken}`,
                    },
                }
            )
                .then((response) => {
                    if (response.status === 200) {
                        alert("Password updated successfully");
                        localStorage.setItem("superAdminToken", response.data.response);
                        setLoading(false);
                        window.location.reload();
                    }
                })
                .catch((error) => {
                    console.error("Error updating password:", error);
                    setLoading(false);
                    alert(error.response.data.message);
                });
        }
    }

    return (
        <div className="flex flex-col justify-center items-center w-full">
            <h1 className="mt-12 font-bold md:text-4xl text-2xl">
                {status === "admin"
                    ? "Change Admin Password"
                    : "Change Super Admin Password"
                }
            </h1>

            <form
                onSubmit={handleFormSubmit}
                className="md:w-1/3 md:h-[65vh] h-[60vh] w-[90%] shadow-xl md:bg-slate-50 bg-slate-200 rounded-lg md:mt-16 mt-10 flex justify-center items-center"
            >
                <div className="flex flex-col items-center md:w-4/5 w-[90%] h-[90%] gap-5 mt-2">
                    <div className="w-full">
                        <label className={`block mb-1 ${error.username ? 'text-red-500 font-bold' : null}`}>Username</label>
                        <input
                            type="text"
                            placeholder="Enter Username"
                            name="username"
                            className={`border-2 rounded-md w-full h-11 pl-3 outline-0 ${error.username ? 'border-red-500' : 'border-gray-400'}`}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className="w-full">
                        <label className={`block mb-1 ${error.oldPassword ? 'text-red-500 font-bold' : null}`}>Old Password</label>
                        <input
                            type="password"
                            placeholder="Enter Old Password"
                            name="oldPassword"
                            className={`border-2 rounded-md w-full h-11 pl-3 outline-0 ${error.oldPassword ? 'border-red-500' : 'border-gray-400'}`}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className="w-full">
                        <label className={`block mb-1 ${error.newPassword ? 'text-red-500 font-bold' : null}`}>New Password</label>
                        <input
                            type="password"
                            placeholder="Enter New Password"
                            name="newPassword"
                            className={`border-2 rounded-md w-full h-11 pl-3 outline-0 ${error.newPassword ? 'border-red-500' : 'border-gray-400'}`}
                            onChange={handleInputChange}
                        />
                    </div>

                    {/* <input
                        type="submit"
                        value="Update Password"
                    /> */}

                    <button
                        type="submit"
                        disabled={loading}
                        className="md:w-1/2 w-full text-white font-bold text-lg bg-[#25395A] h-14 rounded-md mt-10"
                    >
                        {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : "Update Password"}
                    </button>

                </div>
            </form>
        </div>
    );
};
